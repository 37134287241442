(function()
{
  "use strict";

  if (Lum === undefined)
  {
    throw new Error("Missing Lum core");
  }

  Lum.registerNamespace('Lores');

  // We're extending Lum.ElementEditor with some custom functionality.
  Lores.Editor = class extends Lum.ElementEditor
  {
    /**
     * Open an editor.
     */
    static open (element, type, options, onSave, onClose)
    {
      let editor = this.getEditor(element);
      if (!(editor instanceof this))
      { // Haven't created the editor yet.
        console.debug("Creating new editor instance", element, type, options, onSave, onClose);
        if (typeof onSave !== 'function')
        {
          throw new Error("onSave must be a function");
        }

        if (onClose instanceof Lum.ViewController)
        { // The GUI was passed instead of a handler function.
          onClose = this.makeOnClose(onClose);
        }
        else if (typeof onClose !== 'function')
        {
          throw new Error("onClose must be a function or a GUI instance");
        }

        let constructOpts = ('class' in options) ? options.class : options;

        // We always want to set the data. Cannot override this.
        constructOpts.setData = true;

        if (!('useEditKeys' in constructOpts))
        { // Enable by default.
          constructOpts.useEditKeys = true;
        }


        editor = new this(element, type, constructOpts);
        editor.on(this.SAVE,  onSave);
        editor.on(this.CLOSE, onClose);
        console.debug("Created editor", editor, element);
      }
      else
      {
        console.debug("Retreived editor from element", editor, element);
      }

      let openOpts = ('open' in options) ? options.open : {};
      editor.open(openOpts);
      return editor;
    }

    static makeOnClose (gui)
    {
      function onCloseEditor (setVal, changed, oldVal, newVal)
      {
        console.debug("Editor closed", 
        {
          setVal, 
          changed, 
          oldVal, 
          newVal, 
          editor: this,
        });
        gui.trigger('onCloseEditor', setVal, changed, oldVal, newVal, this);
      }
      return onCloseEditor;
    }

  } // class Editor
  
})();